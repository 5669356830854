@font-face {
    font-family: "Pridi";
    src: local('Pridi'), 
      /* url(../fonts/Pridi/Pridi-Bold.ttf) format('truetype'), */
      url(./fonts/Pridi-ExtraLight.ttf) format('truetype'),
      url(./fonts/Pridi-Light.ttf) format('truetype'),
      url(./fonts/Pridi-Medium.ttf) format('truetype'),
      url(./fonts/Pridi-Regular.ttf) format('truetype'),
      url(./fonts/Pridi-SemiBold.ttf) format('truetype')
      /* url(./fonts/Pridi-ExtraLight.ttf)  format('truetype') */
  }
  * {
    font-family: "Pridi" !important;
    font-weight: bold !important;}
  

.rate-container {
    margin-top: 20px;
}
.nav-hide{
    margin-top: -55px;
}
.btn-skip{
    width: 100%;
    color:#808080c4;
    cursor: pointer !important;
}
.btn-skip:hover {
    text-decoration: underline;
}
.skip-bar{
    text-align: center;
}
.btn-confirm{
    width: 100%;
}
.icon-rating{
    display: block
}
.text-rating{
    display: block;
}
.span-rate{
    display: inline-block;
    cursor: pointer;
}
.input-rating{
    border-radius: 15px;
    height: 15vh;
    outline: none;
    width: 100%;
    resize: none;
    margin-bottom: -4px;
    background-color: white;
    border : none;
    padding: 5px;
}
.field-rating{
    background-color: #ECECEC;
    padding: 10px;
    border-radius: 20px;
    text-align: center;
}
.field-end{
    text-align: center;
}
.point-img {
    margin-left: 10px;
    margin-right: 10px;
    width: 60px;
}
.div-icon-rating{
    text-align: center;
}
.header-rating{
    text-align: center;
    font-size: 18px;
}
.header-sub-rating{
    text-align: center;
}
.nav-rating{
    font-size: 22px;
    color: black;
    text-align: center;
    background-color: white !important;
    border-bottom: 1px solid#EFEFEF;
}
.nav {
    line-height: 58px !important;
}
.chatboxheader{
    background-color: transparent !important;
}
.label-rating{
    margin-bottom: 3px;
}
@media only screen and (max-width: 600px) {
    button {
        font-size: 15px;
    }
    div{
        font-size: 15px;
    }
    .point-left {
        margin-right: 10px;
        width: 50px;
    }
    .point-right {
        width: 50px;
    }
}
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

    .text-rating{
        font-size: 14px;
    }
    .point-img{
        width: 32px;
    }
    .label-rating{
        font-size: 14px;
    }
}
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) { 
    .text-rating{
        font-size: 14px;
    }
    .point-img{
        width: 45px;
    }
    .label-rating{
        font-size: 14px;
    }
}

.noselect{
    opacity: 0.3;
}
.navbar-brand-rate{
    text-align: center;
    font-size: 24px;
    width: 100%;
}
.hide{
    display: none;
}
.submit-survey{
    text-align: center !important;
}