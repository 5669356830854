.comment_tag {
    border-radius : 50px !important;
    margin-top: 7px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 7px;
    background-color: white;
    color: black;
}
.comment_select {
    border-radius : 50px !important;
    /* border-color: white; */
    margin-top: 7px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom:  7px;    
    background-color: white;
    /* background-color: #1378B3; */
    /* color: white; */
}
.comment_tag:hover {
    border-radius : 50px !important;
    /* border-color: white !important; */
    margin-top: 7px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom:  7px;  
    background-color: white;
    /* background-color: #1378B3 !important; */
    /* color: white; */
    color: black;
}
.comment_tag {
    outline: none !important;
    box-shadow: none;
    /* background-color: #1378B3 !important; */
    /* transform: translateY(100px); */
 }
 .btn-outline-secondary:active{
     transform: translateY(4px);
     background-color: #1378B3 !important;
 }